body {
    font-family: $body-font !important;
    color: $app-font-color;
}

home {
    font-family: $body-font !important;
}

// Skin label font ////////////////////////////////////////////////////////////////////////////////////////

.label {
    font-weight: bold;
    font-size: rem-calc(8px);
    color: #172328;
}
