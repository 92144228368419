/**
* Custom CSS Variables for configuring theme appearance / look
**/

// Forms
label span, label .labelName {
    color: $app-font-color;
}

label {
    position: relative;

    .labelName, label-choice {
        color: $app-font-color !important;
    }

    &.required {
        input {
            border: 1px solid $dark-grey;
            background: white !important;
            color: $app-font-color;

            &::placeholder {
                color: $dark-grey !important;
                font-style: italic;
                background: white;
            }
        }
    }

    &.invalid {
        background: initial;

        span, .labelName {
            color: $app-error;

            &:after {
                color: $app-error;
            }
        }
    }

    &.validate {
        color: $app-font-color;

        input,
        select,
        .customFile {
            border-color: $dark-grey !important;
            color: $app-font-color;

            &:focus {
                border: 2px solid $app-primary !important;
            }
        }
    }

    .search-icon {
        color: $dark-grey !important;
    }

    &.required {
        input {
            border: 1px solid $dark-grey;
            color: $app-font-color;
        }
    }

}

.login {
    .label-container {
        color: white;
    }
}

.passwordContainer {
    &.validate {
        &:before {
            right: rem-calc(60px);
        }
    }
}

input[type='text'],
input[type='password'],
input[type='number'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='month'],
input[type='week'],
input[type='email'],
input[type='search'],
input[type='tel'],
input[type='time'],
input[type='url'],
input[type='color'],
input[type='file'],
[type=text],
[type=password],
[type=date],
[type=datetime],
[type=datetime-local],
[type=month],
[type=week],
[type=email],
[type=search],
[type=tel],
[type=time],
[type=url],
[type=color],
textarea,
select,
input {
    color: $app-font-color;
    border-color: $dark-grey;

    &:active,
    &:focus {
        box-shadow: 0 rem-calc(2px 4px) rgba($dark-grey, 0.5);
        border: 2px solid $app-primary;
        background: white !important;
    }

    &:hover {
        background: white !important;
        color: $app-font-color !important;
        box-shadow: 0 rem-calc(2px 4px) rgba($dark-grey, 0.5);

        &::placeholder {
            color: $app-font-color !important;
            background: white !important;
        }

        &:disabled {
            background: white !important;
            color: $dark-grey !important;
        }
    }

    &::placeholder {
        font-style: normal;
    }

    &.ng-validate {
        border-color: $dark-grey;
        color: $app-font-color;
    }

    &.ng-dirty {
        border-color: $app-primary;
        color: $app-font-color;

        &:hover {
            color: $app-font-color !important;
        }
    }
}

.search-label.validate .search-icon {
    color: $dark-grey;
}

.note.error {
    color: $app-error;

    &:before {
        color: $app-error;
    }
}

.hiddenError {
    display: none !important;
}

// Ng Select
.validate {
    .ng-select {
        .ng-select-container {
            border-color: $dark-grey;
        }
    }
}

.ng-select {
    .ng-select-container {
        border-color: $dark-grey;

        &:active,
        &:focus,
        &:hover {
            background: white !important;
            color: $app-font-color;
            box-shadow: 0 rem-calc(2px 4px) rgba($dark-grey, 0.5);
            border: 2px solid $app-primary;
        }
    }
}

// Buttons
.switch-paddle {
    .switch-active {
        color: white;
    }
    .switch-inactive {
        color: $dark-grey;
    }
}

// Tabs
.tabs-round-block {
    .tabs-title {
        a:hover {
            color: white !important;
        }
    }
}

// Indemnity
.indemnity-date {
    color: $dark-grey !important;
}


