.swal2-popup {
    width: rem-calc(516px) !important;
    padding: rem-calc(32px) !important;

    .swal2-icon {
        border-color: $app-color !important;
        color: $app-color !important;
    }

    #swal2-content {
        font-size: rem-calc(16px);
        padding-bottom: rem-calc(16px);
        font-weight: bold;
        margin: rem-calc(8px 0);
        color: $app-color;
    }

    .swal2-html-container {
        span, p {
            color: $dark-grey;
            font-weight: initial;
        }
    }

    .swal2-confirm {
        min-width: rem-calc(160px);
        font-size: rem-calc(14px) !important;
        font-weight: 600 !important;
        padding: rem-calc(16px) !important;
        margin-bottom: 0;
        transition: all 1s;
        background-color: $app-color !important;
        border: rem-calc(2px) solid $app-color !important;
        border-radius: $border-radius !important;

        &:hover {
            border: rem-calc(2px) solid $app-color-dark !important;
            color: $app-color-dark;
            background-color: $app-color-dark !important;
        }
    }

    .swal2-cancel {
        min-width: rem-calc(160px);
        font-size: rem-calc(14px) !important;
        font-weight: 600 !important;
        padding: rem-calc(16px) !important;
        margin-bottom: 0;
        transition: all 1s;
        background-color: $light-grey !important;
        border: rem-calc(2px) solid $medium-grey !important;
        border-radius: $border-radius !important;
        color: $dark-grey !important;

        &:hover {
            border: rem-calc(2px) solid $dark-grey !important;
            color: darken($dark-grey, 10%) !important;
            background-color: rgba($medium-grey, 0.3) !important;
        }
    }
}

.delete-popup-information-type {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    p {
        font-weight: bold !important;
        margin: 0 4px;

        &:after {
            content: ",";
        }

        &:last-child:after {
            content: '.';
        }
    }
}

.sweet-content-alert {
    background: rgba(251, 89, 40, 0.15);
    color: $app-color;
    font-weight: 600;
    font-size: 1.4rem;
    padding: 1.6rem;
    border-radius: 0.4rem;
    margin-top: 1.6rem;
    margin-bottom: rem-calc(8px);
}
