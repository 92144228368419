// ---------------------------------------------------------------------- >
// ~ SEARCH HELP
// ---------------------------------------------------------------------- >
// Hierarchy :
// ~  : High level section. Title is in summary, separate by "arrow" at the begin and the end (except search help and summary).
// ==== : Upper level in a section
// == : Sub level in a section
// = : Lower level in a section

// ---------------------------------------------------------------------- >
// ~ SUMMARY
// ---------------------------------------------------------------------- >
// 1 - MISC

// ---------------------------------------------------------------------- >
// ~ MISC
// ---------------------------------------------------------------------- >

// ==== Define Fonts
$body-font: 'BNPP Sans' !important;

// ==== Define Colors
// == Colors by name (based on : http://chir.ag/projects/name-that-color/)
// USE ONLY TO DEFINE COLORS BY FUNCTION, don't use in others vars declaration.


// == Colors by function
// = General

@import "colors";

$app-primary-hover: darken($app-primary, 10%) !default;
$app-secondary: $app-secondary !default;
$app-secondary_light: lighten($app-secondary, 10%) !default;

$gradient-transparent: linear-gradient(45deg, $app-primary 0%, rgba($app-secondary, 0.7) 100%);
$background: #F6F7FB !default;

$app-color: $app-secondary !default;
$app-color-light: #394E98 !default;
$app-color-dark: darken($app-color, 15%) !default;
$app-color-gradient: linear-gradient(45deg, $app-color 0%, rgba($app-color-light, 1) 100%);
$app-color-gradient-transparent: linear-gradient(to bottom, $app-color 0%, rgba($app-color-light, 0.7) 100%);

$app-success: #68b538 !default;
$app-success-dark: #4c8f25 !default;
$app-warning: #ff9400 !default;
$app-error: #D52727 !default;

$white: #ffffff !default;
$white-grey: #F9F9FB !default;
$light-grey: #F9F9FB !default;
$medium-grey: #F3F4F8 !default;
$dark-grey: #A8AFBC !default;
$hover-grey: #A8AFBC !default;
$black: #000000 !default;
$disable: #666666 !default;

// = Fonts color
$app-font-color: #2D2926 !default;

// ==== FONTS
$app-font-family: $body-font !default;

// ==== PATHS
$img-path: '/assets/images/' !default;

$jpg-path: '/assets/jpg/' !default;
$png-path: '/assets/png/' !default;
$svg-path: '/assets/svg/' !default;
$gif-path: '/assets/gif/' !default;

$font-main-path: '/assets/font/' !default;
// < ----------------------------------------------------------------------
// ~ MISC END
// < ----------------------------------------------------------------------

// ---------------------------------------------------------------------- >
// ~ LAYOUT
// ---------------------------------------------------------------------- >

// ==== Metrics
$min-width-app: 0px;
$max-width-app: 1600px;
$app-view-header-padding: 8px 24px;
$app-gutter: 32px;
$app-gutter-sm: 10px;
$app-tabs-nav: 256px;

// ==== Blocs style
$border-radius: 8px;
$box-shadow: 0 0 8px 0 rgba($dark-grey, 0.3);
$box-shadow_hover: 0 8px 16px rgba(black, 0.1);
$box-shadow_press: 0 0px 8px rgba(black, 0.1);
$box-shadow-color: 0 0 8px 2px rgba($app-color, 0.3);
$box-shadow-grey: 0 0 8px 2px rgba($medium-grey, 0.3);

// ==== Popup
$popup-title-color: $app-primary;


// ==== Fix height
$header-height: 80px;


// ---------------------------------------------------------------------- >
// ~ LAYOUT END
// ---------------------------------------------------------------------- >


// ---------------------------------------------------------------------- >
// ~ IMAGES
// ---------------------------------------------------------------------- >

$app-bg: url('/assets/png/app-bg.png');

// ---------------------------------------------------------------------- >
// ~ END IMAGES
// ---------------------------------------------------------------------- >
